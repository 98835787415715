<template>
   <loginForm />
</template>

<script>
import loginForm from '@/components/login/LoginForm.vue';

export default {
    data:()=>({
    }),
    components:{
        loginForm
    }
}
</script>

<style>

/*transition the opacity when the element enters and leaves the DOM*/
.fade2-enter-active, .fade2-leave-active {
  opacity: 1;
  transition: all .5s;
  transform: translateX(100px);
}

/*set opacity to 0 at element's entrance and exit*/
.fade2-enter, .fade2-leave-to {
  opacity: 0; 
  transform: translateX(100px);
}
</style>