import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Alerts from '../views/Alerts.vue'
import Hotels from '../views/Hotels.vue'
import HotelAreasInfo from '../views/HotelAreasInformation.vue'
import HotelRoomsInfo from '../views/HotelRoomsInformation.vue'
import Employees from '../views/Employees.vue'
import Recover from '../views/RecoverPassword.vue'
import Reset from '../views/ChangePassword.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/Recover',
    name: 'Recover',
    component: Recover,
  },
  {
    path: '/Reset/:token',
    name: 'Reset',
    component: Reset,
  },
  {
    path: '/Home',
    name: 'Home',
    meta: {
      requiresAuth: true
    },
    component: Home,
  },
  {
    path: '/Alerts',
    name: 'Alerts',
    meta: {
      requiresAuth: true
    },
    component: Alerts,
  },
  {
    path: '/Hotels',
    name: 'Hotels',
    meta: {
      requiresAuth: true
    },
    component: Hotels,
  },
  {
    path: '/Hotel_Areas',
    name: 'hotelAreas',
    meta: {
      requiresAuth: true
    },
    component: HotelAreasInfo,
  },
  {
    path: '/Hotel_Rooms',
    name: 'HotelRooms',
    meta: {
      requiresAuth: true
    },
    component: HotelRoomsInfo,
  },
  {
    path: '/Employees',
    name: 'Employees',
    meta: {
      requiresAuth: true
    },
    component: Employees,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
