import Vue from 'vue'
import Vuex from 'vuex'
import router from "../router";
import axios from 'axios';

// direccion de conexion local
axios.defaults.baseURL = 'https://adminbackend.prosafe.app'
// axios.defaults.baseURL = 'https://pokeapi.co/api/v2/ability'
// axios.defaults.baseURL = 'http://192.168.20.22:8000'

// direccion de conexion servidor de pruebas
// axios.defaults.baseURL = 'http://prosafebackenddashboard.admin.com'
axios.defaults.withCredentials = true;

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:null,
    loading:false,
    logoutState:true,
    circularshow:false,
    errormessage: '',
    alertshow: false
  },
  mutations: {
    changeCircularState(state){
      Vue.set(state,'circularshow',true);  
    },
    setUser(state,valor){
      Vue.set(state,'user',valor);
      if(valor != null) {
        Vue.set(state,'loading',false);  
        Vue.set(state,'circularshow',false);  
        Vue.set(state,'logoutState',true);  
      }else{
        Vue.set(state,'circularshow',false);  
        Vue.set(state,'logoutState',false); 
      }
    },
    setLoading(state,valor){ 
      Vue.set(state,'loading',valor); 
    },
    setErrormessage(state,valor){ 
      Vue.set(state,'errormessage',valor); 
    },
    setAlertshow(state,valor){ 
      Vue.set(state,'alertshow',valor); 
    }
  },
  actions: {
    async login({dispatch, commit},data) {
      await  axios.get('/sanctum/csrf-cookie');
      await  axios.post("/login",data)
        .then(res => {                
          return dispatch('getUser')
        })
        .catch(err => {
          console.error(err);
          commit('setErrormessage',err.response.data.message);
          commit('setAlertshow',true);
          setTimeout(() => {
            commit('setAlertshow',false);
          }, 3000);
          // alert(err.response.data.message)
        });
    },

    //cierra sesion
    logout({commit}){
      axios.post("/logout")
        .then(res => {
            localStorage.removeItem('hotel_id');
            commit('setUser',null);
            router.push({name:"login"});
        })
        .catch(err => {
            console.error(err);
        });
    },

    //obtiene el user autenticado
    getUser({commit}){
      commit("changeCircularState");
      axios.get("/api/user")
        .then(res => {                
          router.push({ name: "Home" });
          commit('setUser',res.data);
        })
        .catch(err => {
          commit('setUser',null);
            console.error(err);
        });
    },

    //obtiene todos los usuarios
    getUsers(){
              axios.get("/api/getUsers")
                  .then(res => {
                    
                  })
                  .catch(err => {
                      console.error("respuesta",err);
                      this.errormessage = err.response.data.message
                      return this.errormessage
                  });
    },
  },
  modules: {
  }
})
