<template>
    <div>
      <v-data-table
          :headers="headers"
          :items="desserts"
          :items-per-page="10"
          class="elevation-1"
        >
        <template v-slot:no-data>
              <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
            ></v-progress-circular>
        </template>
      </v-data-table>
    </div>
</template>

<script>
import axios from 'axios'; 
import {mapActions, mapState} from 'vuex';

export default {
    data:()=>({
        headers: [
          { text: 'Client Name', value: 'user_name' , align: 'center'},
          { text: 'Phone Number', value: 'phone_number' , align: 'center'},
          { text: 'Role', value: 'hotel_role' , align: 'center'},
          { text: 'Email', value: 'email' , align: 'center'},
          { text: 'Company', value: 'company' , align: 'center'},
          { text: 'Hotel Name', value: 'hotel_name' , align: 'center'},
        ],
        desserts: [
        ],
        userID: 1
    }),
    computed:{
      ...mapState(["circularshow"])
    },
    mounted() {
      this.getclients();
    },
    methods: {
      getclients(){
        axios.get("/api/clients/")
            .then(res => {
                this.desserts=[];
                res.data.forEach(element => {
                    this.desserts.push(element)
                });
            })
            .catch(err => {
                console.error("respuesta",err);
            });
      },
    },
}
</script>

<style escoped>

  .v-progress-circular {
  margin: 1rem;
}

</style>