<template>
    <transition name="slide-fade" appear>
        <v-container fluid fill-height class="fondo">
                    <v-row>
                        <v-card
                        id="cardLogin"
                        elevation="3"
                        class="mx-auto rounded-lg"
                        width="550"
                        height="550"
                        >
                            <ValidationObserver v-slot="{ invalid }">
                                <v-alert dense shaped type="error" v-show="alertshow">
                                    {{ errormessage }}
                                </v-alert>
                                <v-card-title style="mt:10px">
                                    <v-container fluid  mt-1>
                                        <!-- <p align="center" class="display-1" >ProSafe Admin Dashboard</p> -->
                                        <div class="text-center">
                                            <img src="@/assets/prosafe-logo.png" width="60%" alt="">
                                        </div>

                                        <div class="text-center">
                                            <v-progress-circular
                                            v-show="circularshow"
                                                indeterminate
                                                color="primary"
                                            ></v-progress-circular>
                                        </div>
                                    </v-container>
                                </v-card-title>

                                <v-card-text>
                                    <div>
                                        <v-container style="width:70%">
                                            <validation-provider rules="required|email" v-slot="{ errors }">
                                                <v-row>
                                                    <v-flex>
                                                        <v-text-field
                                                        v-model="form.email"
                                                            label="Email"
                                                            type="email"
                                                            clearable
                                                        />
                                                        <span style="color:red">{{ errors[0] }}</span>
                                                    </v-flex>
                                                </v-row>
                                            </validation-provider>
                                            <validation-provider rules="required" v-slot="{ errors }">
                                                <v-row>
                                                    <v-flex>
                                                        <v-text-field
                                                        v-model="form.password"
                                                            label="Password"
                                                            type="password"
                                                            clearable
                                                        />
                                                        <span style="color:red">{{ errors[0] }}</span>
                                                    </v-flex>
                                                </v-row>     
                                            </validation-provider>
                                        </v-container>
                                    </div>       
                                </v-card-text>

                                <v-card-actions >
                                    <v-container mt-5>
                                        <v-layout justify-center align-center pl-15 pr-15 >
                                        <v-btn
                                            class="ma-2"
                                            color="primary"
                                            @click.prevent="login(form)"
                                            :disabled="invalid"
                                            block
                                            >
                                            Log In
                                        </v-btn>
                                    </v-layout>
                                    <v-layout justify-center align-center pl-15 pr-15 >
                                        <a style="color:primary" @click="recover">Retrieve Password</a>
                                    </v-layout>
                                    </v-container>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                    </v-row>
        </v-container>
    </transition>
</template>
<script>
import {mapMutations,mapActions, mapState} from 'vuex';
import { ValidationProvider,ValidationObserver , extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});
extend('email', {
  ...email,
  message: 'Must be a valid email'
});

export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data:()=>({
        user: {},
        form:{
            email: '',
            password: '',
            show: false,
            type: 'error'
        },
    }),
    mounted() {
       
    },
    computed:{
      ...mapState(["circularshow", 'errormessage','alertshow'])
    },
    methods: {
        ...mapActions(['login','logout','getUser','getUsers']),
        redirect(){
            this.$router.push({ name: "Home"});
        },
        recover(){
            this.$router.push({ name: "Recover"});
        },
        
    }
}
</script>

<style>
.fondo{
    background-image: url('../../assets/image/fondo.jpg');
    background-size: cover;
    background-position: center;
}

</style>