<template>
    <div>
        <navigationDrawer />
        <treeview />
    </div>
</template>
<script>
import navigationDrawer from '@/components/navigation_drawer.vue';
import treeview from '@/components/EmployeesView.vue';

export default {
    data:()=>({
       
    }),
    components:{
        navigationDrawer,
        treeview  
    }
}
</script>