var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide-fade","appear":""}},[_c('v-container',{staticClass:"fondo",attrs:{"fluid":"","fill-height":""}},[_c('v-row',[_c('v-card',{staticClass:"mx-auto rounded-lg",attrs:{"id":"cardLogin","elevation":"3","width":"550","height":"550"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],attrs:{"prominent":"","type":_vm.type}},[_vm._v(" "+_vm._s(_vm.msg)+" ")]),_c('v-card-title',{staticStyle:{"margin-top":"50px"}},[_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":require("@/assets/prosafe-logo.png"),"width":"60%","alt":""}})]),_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.circularshow),expression:"circularshow"}],attrs:{"indeterminate":"","color":"primary"}})],1)])],1),_c('v-card-text',[_c('div',[_c('v-container',{staticStyle:{"width":"70%"}},[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',[_c('v-flex',[_c('v-text-field',{attrs:{"label":"Email","type":"email","clearable":"","persistent-placeholder":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))])],1)],1)]}}],null,true)}),_c('validation-provider',{attrs:{"vid":"form.password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',[_c('v-flex',[_c('v-text-field',{attrs:{"label":"Password","type":"password","clearable":"","persistent-placeholder":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))])],1)],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|confirmed:form.password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',[_c('v-flex',[_c('v-text-field',{attrs:{"label":"Confirm Password","type":"password","clearable":"","persistent-placeholder":""},model:{value:(_vm.form.confirm_password),callback:function ($$v) {_vm.$set(_vm.form, "confirm_password", $$v)},expression:"form.confirm_password"}}),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))])],1)],1)]}}],null,true)})],1)],1)]),_c('v-card-actions',[_c('v-container',[_c('v-layout',{attrs:{"justify-center":"","align-center":"","pl-15":"","pr-15":""}},[_c('v-btn',{attrs:{"color":"primary","disabled":invalid,"block":""},on:{"click":function($event){$event.preventDefault();return _vm.change()}}},[_vm._v(" CONFIRM ")])],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }