<template>
    <div>
        <navigationDrawer />
        <alertsTable />
    </div>
</template>
<script>
import navigationDrawer from '@/components/navigation_drawer.vue';
import alertsTable from '@/components/AlertsTable.vue';

export default {
    data:()=>({
       
    }),
    components:{
        navigationDrawer,
        alertsTable   
    }
}
</script>