<template>
    <div>
    <v-container fluid>
        <v-layout>
            <navigationDrawer />
        </v-layout>
    </v-container>
    <v-container >
        <v-layout>
            <HomeDashboard />
        </v-layout>
    </v-container>
    </div>
</template>
<script>
import navigationDrawer from '@/components/navigation_drawer.vue';
import HomeDashboard from '@/components/HomeDashboard.vue';

export default {
    data:()=>({
       
    }),
    components:{
        navigationDrawer,
        HomeDashboard  
    }
}
</script>