<template>
   <ChangePassForm />
</template>
<script>
import ChangePassForm from '@/components/login/ChangePassForm.vue';
export default {
    data:()=>({
    }),
    components:{
        ChangePassForm
    }
}
</script>