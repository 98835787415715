<template>
    <div>
        <navigationDrawer />
        <hotelAreas />
    </div>
</template>
<script>
import navigationDrawer from '@/components/navigation_drawer.vue';
import hotelAreas from '@/components/HotelAreasTable.vue';

export default {
    data:()=>({
       
    }),
    components:{
        navigationDrawer,
        hotelAreas
    }
}
</script>