<template>
<transition name="slide-fade" appear>
    <v-container fluid fill-height class="fondo">
        <v-row>
            <v-card
            id="cardLogin"
            elevation="3"
            class="mx-auto rounded-lg"
            width="550"
            height="550"
            >
                <ValidationObserver v-slot="{ invalid }">
                    <v-alert prominent :type="type" v-show="show">
                        {{ msg }}
                    </v-alert>
                    <v-card-title style="margin-top:50px">
                        <v-container fluid  mt-1>
                            <!-- <p align="center" class="display-1" >ProSafe Admin Dashboard</p> -->
                            <div class="text-center">
                                <img src="@/assets/prosafe-logo.png" width="60%" alt="">
                            </div>
                            <div class="text-center">
                                <v-progress-circular
                                v-show="circularshow"
                                    indeterminate
                                    color="primary"
                                ></v-progress-circular>
                            </div>
                        </v-container>              
                    </v-card-title>
                    <v-card-text>
                        <div>
                            <v-container style="width:70%">
                                <validation-provider rules="required|email" v-slot="{ errors }">
                                    <v-row>
                                        <v-flex>
                                            <v-text-field
                                            v-model="email"
                                                label="Email"
                                                type="email"
                                                clearable
                                            />
                                            <span style="color:red">{{ errors[0] }}</span>
                                        </v-flex>
                                    </v-row>
                                </validation-provider>
                            </v-container>
                        </div>       
                    </v-card-text>

                    <v-card-actions >
                        <v-container mt-5>
                            <v-layout justify-center align-center pl-15 pr-15 >
                            <v-btn
                                    class="ma-2"
                                    color="primary"
                                    @click.prevent="recover()"
                                    :disabled="invalid"
                                    block
                                    >
                                    SEND
                                </v-btn>
                            </v-layout>
                            <v-layout justify-center align-center pl-15 pr-15 >
                                <a style="color:primary" @click="redirect">Back to Login</a>
                            </v-layout>
                        </v-container>
                    </v-card-actions>
                </ValidationObserver>
            </v-card>
        </v-row>
    </v-container>
</transition>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import axios from 'axios';
import { ValidationProvider,ValidationObserver , extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});
extend('email', {
  ...email,
  message: 'Must be a valid email'
});

export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data:()=>({
        user: {},
        email: '',
        show: false,
        type: null,
        msg: ''
    }),
    computed:{
      ...mapState(["circularshow"])
    },
    methods: {
        async recover(){
            await  axios.get('/sanctum/csrf-cookie');
            await axios.post('api/password/email',{
                email: this.email
            })
            .then(res => {
                this.msg= res.data.message;
                this.show= true;
                this.type= 'success';
                setTimeout(() => {
                    this.show=false;
                    this.redirect();
                }, 3000);
            })
            .catch(err => {
                console.error(err);
                this.msg= err.response.data.message;
                this.show= true;
                this.type= 'error';
                setTimeout(() => {
                    this.show=false;
                }, 3000);
            });
        },
        redirect(){
            this.$router.push({ name: "Login"}); 
        },
        showAlert(type){

        }
    }
}
</script>

<style>

.fondo{
    background-image: url('../../assets/image/fondo.jpg');
    background-size: cover;
    background-position: center;
}

</style>