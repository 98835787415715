<template>
<transition name="slide-fade" appear>
    <v-container>
          <v-layout row wrap justify-center>
              <v-card style="margin-top:60px" width="90%" height="500" elevation="0">
                    <v-card-title class="justify-center">
                      Registered Hotels
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="desserts"
                        :items-per-page="10"
                        class="elevation-1"
                      >
                      <template v-slot:item.actions="{ item }">
                          <v-icon
                              large
                              class="mr-2"
                              color="green"
                              @click="checkEmployees(item)"
                          >
                              mdi-account-search
                          </v-icon>
                          <v-icon
                              large
                              color="red"
                              @click="checkAlerts(item)"
                          >
                              mdi-alarm-bell
                          </v-icon>
                        </template>
                      <template v-slot:item.qrrooms="{ item }">
                          <v-icon
                              large
                              color="black"
                              @click="checkHotelRoomsInformation(item)"
                          >
                              mdi-qrcode-scan
                          </v-icon>
                        </template>
                      <template v-slot:item.qrareas="{ item }">
                          <v-icon
                              large
                              color="black"
                              @click="checkHotelAreasInformation(item)"
                          >
                              mdi-qrcode-scan
                          </v-icon>
                        </template>
                        <template v-slot:no-data>
                              <v-progress-circular
                              :size="70"
                              :width="7"
                              color="primary"
                              indeterminate
                            ></v-progress-circular>
                        </template>
                    </v-data-table>
              </v-card>
          </v-layout>     
    </v-container>
</transition>
</template>

<script>
import axios from 'axios'; 

export default {
    data:()=>({
        headers: [
          { text: 'ID', value: 'id' },
          {
          text: 'Hotel_Name',
          align: 'start',
          value: 'name_property',
          },
          { text: 'Address', value: 'address' , align: 'center'},
          { text: 'Phone Number', value: 'phone_number' , align: 'center'},
          { text: 'Fax Number', value: 'fax_number' , align: 'center'},
          { text: 'Local Number', value: 'local_number' , align: 'center'},
          { text: 'View', value: 'actions', sortable: false , align: 'center'},
          { text: 'Rooms', value: 'qrrooms', sortable: false , align: 'center'},
          { text: 'Areas', value: 'qrareas', sortable: false , align: 'center'},
        ],
        desserts: [
        ],
    }),
    mounted() {
      this.getHotels();
    },
    methods: {
      async getHotels(){
        await axios.get("/api/hotels")
            .then(res => {
                this.desserts=[];
                res.data.forEach(element => {
                    this.desserts.push(element)
                });
            })
            .catch(err => {
                console.error("respuesta",err);
            });
      },
      checkEmployees(item){
        localStorage.setItem('hotel_id', item.id)
        this.$router.push({ name: "Employees"});
      },
      checkAlerts(item){
        localStorage.setItem('hotel_id', item.id)
        this.$router.push({ name: "Alerts"});
      },
      checkHotelRoomsInformation(item){
        localStorage.setItem('hotel_id', item.id)
        this.$router.push({ name: "HotelRooms"});
      },
      checkHotelAreasInformation(item){
        localStorage.setItem('hotel_id', item.id)
        this.$router.push({ name: "hotelAreas"});
      },
      qrList() {
            window.open("http://localhost:8000/api/hotel_info/1", "_blank").focus();
        },
    },
}
</script>

<style escoped>

  .v-progress-circular {
  margin: 1rem;
}

</style>