<template>
  <v-app>
    <v-main>
      <router-view>
      </router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>


<style scoped>

 .v-application .error--text{
    color: #ff3838 !important;
    caret-color: #ff3838 !important;
    font-family: 'Montserrat', sans-serif !important;
}
.v-application{
  font-family: 'Montserrat', sans-serif !important;
}

.globalFont{
  font-family: 'Montserrat', sans-serif !important;
}

.v-application .slide-fade-enter-active {
  transition: all .4s ease;
}
.v-application .slide-fade-leave-active {
  transition: all .6s cubic-bezier(.17, .67,.83, .67);
}
.v-application .slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(800px);
  opacity: 0;
}

</style>
