import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@mdi/font/css/materialdesignicons.css'

import axios from 'axios'
import VueAxios from 'vue-axios'


Vue.use(VueAxios,axios)

Vue.config.productionTip = false


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    axios.get("/api/user")
      .then(res => {
          if(res!=null && res!=undefined){
            store.commit('setUser',res);
            next();
          }else{
            next({ name: "Home" });
          }
      })
      .catch(err => {
        next({ name: "Home" });
      });
  } else {
    next();
  }
})

const Mixin = {
  /**
   * Avoids redundand error when navigating to already active page
   */
  methods:{
    routerPush(route) {
      this.$router.push(route).catch((error) => {
        if(error.name != "NavigationDuplicated") {
          throw error;
        }
      })
    }
  }
}


new Vue({
  router,
  store,
  vuetify,
  mixins: ['Mixin'],
  render: h => h(App)
}).$mount('#app')
