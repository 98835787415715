<template>
<transition name="slide-fade" appear>
    <v-container>
        <v-layout row wrap justify-center>
            <v-card style="margin-top:60px" width="80%" height="500" elevation="0">
            <v-alert :type="type" v-show="show">
              {{ msg }}
            </v-alert>
                    <v-card-title class="justify-center">
                      Hotels Alerts Log
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="desserts"
                        :items-per-page="10"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        class="elevation-1"
                      >
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                            v-if="item.status !== 'Pending'"
                            large
                            @click="editAlert(item)"
                            color="red"
                        >
                            mdi-alarm-light-off-outline
                        </v-icon>
                        <v-icon 
                            v-else="item.status === 'Pending'"
                            large
                            @click="editAlert(item)"
                            color="green"
                        >
                            mdi-alarm-light
                        </v-icon>
                      </template>
                      <template v-slot:no-data>
                            <v-progress-circular
                            :size="70"
                            :width="7"
                            color="primary"
                            indeterminate
                          ></v-progress-circular>
                      </template>
                    </v-data-table>
            </v-card>
        </v-layout>     
    </v-container>
</transition>
</template>

<script>
import axios from 'axios';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  computed: {
  },
    data:()=>({
        sortBy: "date_user",
        sortDesc: true,
        headers: [
          { text: 'Hotel Name', value: 'hotelName' },
          { text: 'Employee Name', value: 'userName' },
          { text: 'Alert Date', value: 'date_user' },
          { text: 'Status', value: 'status' },
          { text: 'Alarm On/Off', value: 'actions' },
        ],
        desserts: [
        ],
        hotel_id: null,
        type: 'success',
        show: false,
        msg: ''
    }),
    methods: {
      getAlerts(){
        if (this.hotel_id !== null) {
             axios.get("/api/alerts_hotel/"+this.hotel_id)
            .then(res => {
                this.desserts=[];
                this.desserts = [...res.data]
            })
            .catch(err => {
                console.error("respuesta",err);
            });
        } else {
           axios.get("/api/alerts")
            .then(res => {
                this.desserts=[];
                this.desserts = [...res.data]
            })
            .catch(err => {
              console.error("respuesta",err);
            });
        
        }
      },
       refreshView(){
        axios.get("http://localhost:3000/api/refresh_view/"+this.hotel_id)
            .then(res => {
                console.log(res.data);
            })
            .catch(err => {
                console.error("respuesta",err);
            });
    },
      editAlert(item){
        if (item.status !== 'Pending') {
          let status_id = 6;
          item.new_status_id = status_id;
          item.hotel_id = this.hotel_id;
          axios.put('/api/alertUpdate/', item)
            .then(res => {
                this.msg = res.data.message;
                this.show=true;
                this.type= 'success';
                setTimeout(() => {
                  this.show=false;
                }, 3000);
                this.getAlerts();
                // axios.get("http://localhost:3000/api/refresh_view/")
                this.refreshView();
            })
            .catch(err => {
              console.error(err);
            })
        }else {
            this.msg = 'The alarm is already Off';
            this.show=true;
            this.type= 'warning';
            setTimeout(() => {
              this.show=false;
            }, 3000);
          }
      },
    },
    mounted() {
        // localStorage.removeItem('hotel_id');
      this.hotel_id = localStorage.getItem('hotel_id');
      this.getAlerts();
    },
    created() {
    },
    beforeCreate() {
    },
}
</script>

<style escoped>

  .v-progress-circular {
  margin: 1rem;
}

</style>