<template>
<transition name="slide-fade" appear>
    <div>
        <v-container class="mt-10" >
           <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
              absolute
              rounded="pill"
            >
            <strong>
              {{ snackBarText }}
            </strong>
              <template v-slot:action="{ attrs }">
                 <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">Close</v-btn> 
              </template>
            </v-snackbar>
            <!-- <v-card style="margin-top:20px; margin-bottom:100px" width="30%" height="100" elevation="4"> -->
            <v-card style="margin-top:20px; margin-bottom:80px" class="mx-auto;" max-width="344" outlined elevation="3" v-show="show">
              <v-card-title>HOTEL ADMIN INFO</v-card-title>
              <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                    <p>
                      Name: {{this.name}}
                    </p>
                    </div>
                    <div class="text-overline mb-4">
                    <p>
                      Email: {{this.email}}
                    </p>
                    </div>
                    <div class="text-overline mb-4">
                    <p>
                      Hotel Name: {{this.hotel_name}}
                    </p>
                    </div>
                  </v-list-item-content>
              </v-list-item>
              <div class="ma-2">
                <v-menu 
                  transition="slide-x-transition"
                  bottom
                  right
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dense
                      small
                      class="deep-orange"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      OPTIONS
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item class="MenuStyle">
                      <v-list-item-title class="listMenuStyle" @click="showModal('admin')"><b>Update Administrator Credentials</b></v-list-item-title>
                    </v-list-item>
                    <v-list-item class="MenuStyle">
                      <v-list-item-title class="listMenuStyle" @click="showModal('allUsers')"><b>Update 6-Digit Pin For All Associates</b></v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-card>
            <v-card>
                <v-card-title class="primary white--text text-h5">
                Employees Directory
                </v-card-title>
                <v-row
                class="pa-4"
                justify="space-between"
                >
                <v-col cols="5">
                    <v-treeview
                    :active.sync="active"
                    :items="items"
                    :load-children="fetchUsers"
                    :open.sync="open"
                    activatable
                    color="warning"
                    open-on-click
                    transition
                    >
                    <template v-slot:prepend="{ item }">
                        <v-icon v-if="!item.children">
                        mdi-account
                        </v-icon>
                    </template>       
                    </v-treeview>
                </v-col>

                <v-divider vertical></v-divider>

                <v-col
                    class="d-flex text-center"
                >
                    <v-scroll-y-transition mode="out-in">
                    <div
                        v-if="!selected"
                        class="text-h6 grey--text text--lighten-1 font-weight-light"
                        style="align-self: center;"
                    >
                        Select a Employee
                    </div>
                    <v-card
                        v-else
                        :key="selected.id"
                        class="pt-6 mx-auto"
                        flat
                        max-width="400"
                    >
                        <v-card-text>
                            <v-avatar
                                v-if="avatar"
                                size="88"
                            >
                                <v-img
                                :src="`https://avataaars.io/${avatar}`"
                                class="mb-6"
                                ></v-img>
                            </v-avatar>
                            <h3 class="text-h5 mb-2">
                                {{ selected.name }}
                            </h3>
                            <div class="mb-2">
                                {{ selected.email }}
                            </div>
                            <div class="blue--text subheading font-weight-bold">
                              <a
                              @click="showModal('user' ,selected.id)"
                              >
                              Change 6-digit Pin
                              <v-icon color="primary">mdi-pencil</v-icon>
                              </a> 
                            </div>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-row
                        class="text-left"
                        tag="v-card-text"
                        >
                            <v-col
                                class="text-right mr-4 mb-2"
                                tag="strong"
                                cols="5"
                            >
                                Company:
                            </v-col>
                            <v-col>{{ selected.company }}</v-col>
                            <v-col
                                class="text-right mr-4 mb-2"
                                tag="strong"
                                cols="5"
                            >
                                Brand:
                            </v-col>
                            <v-col>{{ selected.brand }}</v-col>
                            <v-col
                                class="text-right mr-4 mb-2"
                                tag="strong"
                                cols="5"
                            >
                                Phone:
                            </v-col>
                            <v-col>{{ selected.phone }}</v-col>
                        </v-row>
                    </v-card>
                    </v-scroll-y-transition>
                </v-col>
                </v-row>
            </v-card>
        </v-container>
        <v-row justify="center">
            <v-dialog
                v-model="dialog"
                persistent
                max-width="500"
                >
                    <v-card>
                        <ValidationObserver v-slot="{ invalid }">
                            <v-toolbar v-if="modalOption === 'admin'"
                                flat
                                color="#06bef0"
                                dark
                            >
                                <div style="float:right">
                                    <v-btn icon  @click="dialog=!dialog">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </div>
                                <v-toolbar-title>Administrator</v-toolbar-title>
                            </v-toolbar>
                            <v-toolbar v-else
                                flat
                                color="#06bef0"
                                dark
                            >
                                <div style="float:right">
                                    <v-btn icon  @click="dialog=!dialog">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </div>
                                <v-toolbar-title>Associates</v-toolbar-title>
                            </v-toolbar>
                            <v-tabs color="#06bef0">
                                <v-tab v-if="modalOption === 'admin'">
                                    <v-icon left >
                                        mdi-account-edit-outline
                                    </v-icon>
                                    Credentials
                                </v-tab>

                                <v-tab v-else>
                                    <v-icon left>
                                        mdi-account-edit-outline
                                    </v-icon>
                                    Change Pin
                                </v-tab>

                                <v-tab-item>
                                    <v-card flat>
                                        <div class="text-center" style="margin-top:2%">
                                            <img class="rounded-circle " style="margin-top:20px" src="/img/associates_import-Icon.png" width="50%" alt="">
                                            <!-- <v-card-title class="headline">
                                                Associate Information
                                            </v-card-title> -->
                                        </div>
                                        <v-card-text v-if="modalOption === 'admin'">
                                            <v-form style="margin: 30px 20px 20px 20px">
                                                  <validation-provider rules="required|email" v-slot="{ errors }">
                                                      <v-row mb-3>
                                                          <v-col >
                                                              <v-text-field
                                                              v-model="paramsAdmin.email"
                                                                  :error-messages="errors"
                                                                  label="Email"
                                                                  type="email"
                                                                  clearable
                                                                  persistent-placeholder
                                                              />
                                                          </v-col>
                                                      </v-row>
                                                  </validation-provider>
                                                  <validation-provider rules="" v-slot="{ errors }">
                                                      <v-row mb-3>
                                                          <v-col >
                                                              <v-text-field
                                                              v-model="paramsAdmin.name"
                                                                  :error-messages="errors"
                                                                  label="Administrator Name"
                                                                  type="text"
                                                                  clearable
                                                                  persistent-placeholder
                                                              />
                                                          </v-col>
                                                      </v-row>
                                                  </validation-provider>
                                                  <validation-provider vid="form.password" rules="min:8" v-slot="{ errors }">
                                                      <v-row mb-3>
                                                          <v-col >
                                                              <v-text-field
                                                              v-model="paramsAdmin.password"
                                                                  :error-messages="errors"
                                                                  label="Password"
                                                                  clearable
                                                                  persistent-placeholder
                                                                  :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                                                                  @click:append="() => (value = !value)"
                                                                  :type="value ? 'password' : 'text'"
                                                              />
                                                          </v-col>
                                                      </v-row>
                                                  </validation-provider>
                                                  <validation-provider rules="confirmed:form.password" v-slot="{ errors }">
                                                      <v-row mb-3>
                                                          <v-col >
                                                              <v-text-field
                                                                  v-model="paramsAdmin.confirmed"
                                                                  :error-messages="errors"
                                                                  label="Confirm Password"
                                                                  type="password"
                                                                  clearable
                                                                  persistent-placeholder
                                                              />
                                                          </v-col>
                                                      </v-row>     
                                                  </validation-provider>
                                                  <validation-provider vid="form.pin" rules="minPin:6" v-slot="{ errors }">
                                                      <v-row mb-3>
                                                          <v-col >
                                                              <v-text-field
                                                              v-model="paramsAdmin.pin"
                                                                  :error-messages="errors"
                                                                  label="Pin"
                                                                  clearable
                                                                  persistent-placeholder
                                                                  :append-icon="value1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                                  @click:append="() => (value1 = !value1)"
                                                                  :type="value1 ? 'password' : 'text'"
                                                              />
                                                          </v-col>
                                                      </v-row>
                                                  </validation-provider>
                                                  <div style="text-align:center; padding-bottom:20px; margin-top:2px">
                                                      <v-btn
                                                          class="ma-2"
                                                          color="#06bef0"
                                                          dark
                                                          small
                                                          @click.prevent="updateAdminCredentials()"
                                                          :disabled="invalid"
                                                      >
                                                          <v-icon
                                                          dark
                                                          right
                                                          >
                                                          mdi-content-save-edit-outline
                                                          </v-icon>
                                                          <span align="center" style="margin-left:15px">Update</span>
                                                      </v-btn>
                                                  </div>
                                            </v-form>
                                            <v-overlay
                                              :opacity="0"
                                              :value="overlay"
                                              >
                                                  <v-progress-circular
                                                  :size="70"
                                                  :width="7"
                                                  color="primary"
                                                  indeterminate
                                                  ></v-progress-circular>
                                              </v-overlay>
                                        </v-card-text>

                                        <v-card-text v-else-if="modalOption === 'allUsers'">
                                            <v-form style="margin: 30px 20px 20px 20px">
                                                  <validation-provider vid="form.pin" rules="required|min:3" v-slot="{ errors }">
                                                      <v-row mb-3>
                                                          <v-col >
                                                              <v-text-field
                                                              v-model="params.pin"
                                                                  :error-messages="errors"
                                                                  label="pin"
                                                                  clearable
                                                                  persistent-placeholder
                                                                   :append-icon="value1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                                  @click:append="() => (value1 = !value1)"
                                                                  :type="value1 ? 'password' : 'text'"
                                                              />
                                                              <!-- <span style="color:red">{{ errors[0] }}</span> -->
                                                          </v-col>
                                                      </v-row>
                                                  </validation-provider>
                                                  <validation-provider rules="required|confirmed:form.pin" v-slot="{ errors }">
                                                      <v-row mb-3>
                                                          <v-col >
                                                              <v-text-field
                                                                  v-model="params.confirmed"
                                                                  :error-messages="errors"
                                                                  label="Confirm Pin"
                                                                  type="password"
                                                                  clearable
                                                                  persistent-placeholder
                                                              />
                                                              <!-- <span style="color:red">{{ errors[0] }}</span> -->
                                                          </v-col>
                                                      </v-row>     
                                                  </validation-provider>
                                                  <div style="text-align:center; padding-bottom:20px; margin-top:2px">
                                                      <v-btn
                                                          class="ma-2"
                                                          color="#06bef0"
                                                          dark
                                                          small
                                                          @click.prevent="updatePinAllUsers()"
                                                          :disabled="invalid"
                                                      >
                                                          <v-icon
                                                          dark
                                                          right
                                                          >
                                                          mdi-content-save-edit-outline
                                                          </v-icon>
                                                          <span align="center" style="margin-left:15px">Update</span>
                                                      </v-btn>
                                                  </div>
                                            </v-form>
                                        </v-card-text>

                                        <v-card-text v-else-if="modalOption === 'user'">
                                             <v-form style="margin: 30px 20px 20px 20px">
                                                  <validation-provider vid="form.pin" rules="required|minPin:6" v-slot="{ errors }">
                                                      <v-row mb-3>
                                                          <v-col >
                                                              <v-text-field
                                                              v-model="params.pin"
                                                                  :error-messages="errors"
                                                                  label="Pin"
                                                                  clearable
                                                                  persistent-placeholder
                                                                  :append-icon="value1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                                  @click:append="() => (value1 = !value1)"
                                                                  :type="value1 ? 'password' : 'text'"
                                                              />
                                                              <!-- <span style="color:red">{{ errors[0] }}</span> -->
                                                          </v-col>
                                                      </v-row>
                                                  </validation-provider>
                                                  <validation-provider rules="required|confirmedPin:form.pin" v-slot="{ errors }">
                                                      <v-row mb-3>
                                                          <v-col >
                                                              <v-text-field
                                                                  v-model="params.confirmed"
                                                                  :error-messages="errors"
                                                                  label="Confirm Pin"
                                                                  type="password"
                                                                  clearable
                                                                  persistent-placeholder
                                                              />
                                                              <!-- <span style="color:red">{{ errors[0] }}</span> -->
                                                          </v-col>
                                                      </v-row>     
                                                  </validation-provider>
                                                  <div style="text-align:center; padding-bottom:20px; margin-top:2px">
                                                      <v-btn
                                                          class="ma-2"
                                                          color="#06bef0"
                                                          dark
                                                          small
                                                          @click.prevent="updatePin()"
                                                          :disabled="invalid"
                                                      >
                                                          <v-icon
                                                          dark
                                                          right
                                                          >
                                                          mdi-content-save-edit-outline
                                                          </v-icon>
                                                          <span align="center" style="margin-left:15px">Update</span>
                                                      </v-btn>
                                                  </div>
                                            </v-form>
                                        </v-card-text>    
                                    </v-card>
                                </v-tab-item>
                            </v-tabs>
                        </ValidationObserver>
                    </v-card>
            </v-dialog>
        </v-row>
    </div>
</transition>
</template>

<script>
import axios from 'axios';
import { ValidationProvider,ValidationObserver , extend } from 'vee-validate';
import { required, email, confirmed, min } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});
extend('email', {
  ...email,
  message: 'Must be a valid email'
});
extend('confirmed', {
  ...confirmed,
  message: 'Password does not match '
});
extend('confirmedPin', {
  ...confirmed,
  message: 'Pin does not match '
});
extend('min', {
  ...min,
  message: 'Password must be at least 8 characters '
});
extend('minPin', {
  ...min,
  message: 'Pin must be at least 6 characters '
});

  const avatars = [
    '?accessoriesType=Blank&avatarStyle=Circle&clotheColor=PastelGreen&clotheType=ShirtScoopNeck&eyeType=Wink&eyebrowType=UnibrowNatural&facialHairColor=Black&facialHairType=MoustacheMagnum&hairColor=Platinum&mouthType=Concerned&skinColor=Tanned&topType=Turban',
    '?accessoriesType=Sunglasses&avatarStyle=Circle&clotheColor=Gray02&clotheType=ShirtScoopNeck&eyeType=EyeRoll&eyebrowType=RaisedExcited&facialHairColor=Red&facialHairType=BeardMagestic&hairColor=Red&hatColor=White&mouthType=Twinkle&skinColor=DarkBrown&topType=LongHairBun',
    '?accessoriesType=Prescription02&avatarStyle=Circle&clotheColor=Black&clotheType=ShirtVNeck&eyeType=Surprised&eyebrowType=Angry&facialHairColor=Blonde&facialHairType=Blank&hairColor=Blonde&hatColor=PastelOrange&mouthType=Smile&skinColor=Black&topType=LongHairNotTooLong',
    '?accessoriesType=Round&avatarStyle=Circle&clotheColor=PastelOrange&clotheType=Overall&eyeType=Close&eyebrowType=AngryNatural&facialHairColor=Blonde&facialHairType=Blank&graphicType=Pizza&hairColor=Black&hatColor=PastelBlue&mouthType=Serious&skinColor=Light&topType=LongHairBigHair',
    '?accessoriesType=Kurt&avatarStyle=Circle&clotheColor=Gray01&clotheType=BlazerShirt&eyeType=Surprised&eyebrowType=Default&facialHairColor=Red&facialHairType=Blank&graphicType=Selena&hairColor=Red&hatColor=Blue02&mouthType=Twinkle&skinColor=Pale&topType=LongHairCurly',
  ]

  const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

  export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data: () => ({
      value: String,
      value1: String,
      snackbar: false,
      overlay: false,
      snackBarText: '',
      timeout: 3000,
      modalOption: null,
      active: [],
      avatar: null,
      dialog:false,
      params:{
        id:"",
        pin:"",
        confirmed:"",
      },
      paramsAdmin:{
        id:"",
        email:"",
        name:"",
        password:"",
        pin:"",
        confirmed:"",
        confirmedPin:"",
      },
      open: [],
      users: [],
      dataUsers: [],
      name: '',
      email: '',
      hotel_name: '',
      hotel_id: null,
      show: false
    }),

    computed: {
      items () {
        return [
          {
            name: 'Employees',
            children: this.users,
          },
        ]
      },
      selected () {
        if (!this.active.length) return undefined
        const id = this.active[0]
        return this.users.find(user => user.id === id)
      },
    },
    watch: {
      selected: 'randomAvatar',
    },
    methods: {
      async fetchUsers (item) {
        // console.log(item);
          if(this.hotel_id != null){
              await pause(1500)
              return axios.get("/api/employees_hotel/"+this.hotel_id)
                .then(res => {
                  let dataArray = res.data
                  item.children.push(...dataArray)
                })
                .catch(err => console.warn(err))
          }else{
              await pause(1500)
              return axios.get("/api/employees")
                .then(res => {
                  let dataArray = res.data
                  item.children.push(...dataArray)
                  
                })
                .catch(err => console.warn(err))
          }    
      },
      getUsers(){
          axios.get("/api/employees_hotel/"+this.hotel_id)
            .then(res => {
              this.dataUsers=[];
              this.dataUsers = [...res.data]
            })
            .catch(err => console.warn(err))
      },
      showModal( option,id){
            if (option === 'admin') {
              this.modalOption = option;
              if (this.modalOption != null) {
                this.dialog = true;
                this.paramsAdmin.email = this.email;
                this.paramsAdmin.name = this.name;
                this.overlay = false;
              }
            }
            else if (option === 'allUsers') {
              this.modalOption = option;
              if (this.modalOption != null) {
                this.dialog = true;
              }
            }
            else if (option === 'user') {
              this.modalOption = option; 
              if (this.modalOption != null) {
                this.dialog = true;
                this.params.id= id;
              }
            }
      },
      randomAvatar () {
        this.avatar = avatars[Math.floor(Math.random() * avatars.length)]
      },
      admininfo () {
        if (this.hotel_id) {
          axios.get("/api/admin/"+this.hotel_id)
            .then(res => {
                this.paramsAdmin.id = res.data.id;
                this.name = res.data.name;
                this.email = res.data.email;
                this.hotel_name = res.data.hotel.name_property;
                this.show = true;
            })
            .catch(err => {
                console.error("respuesta",err);
                this.show = false;
            });
        }else{
            this.show = false;
        }
      },
      updatePin(){
          axios.put('/api/employeesUpdatePin', this.params)
            .then(res => {
                // this.msg = res.data.message;
                // this.showAlert=true;
                // this.type= 'success';
                // setTimeout(() => {
                //   this.show=false;
                // }, 3000);
                this.dialog = false;
                this.snackbar = true;
                this.snackBarText = res.data.message;
            })
            .catch(err => {
              console.error('err', err);
            })
      },
      updatePinAllUsers(){
        let newpin = this.params.pin
        this.dataUsers.forEach(element => {
          element['pin'] = newpin;
        });
          axios.put('/api/employeesUpdateAllUsersPin', this.dataUsers)
            .then(res => {
                // this.msg = res.data.message;
                // this.showAlert=true;
                // this.type= 'success';
                // setTimeout(() => {
                //   this.show=false;
                // }, 3000);
                this.dialog = false;
                this.snackbar = true;
                this.snackBarText = res.data.message;
            })
            .catch(err => {
              console.error(err);
            })
      },
       updateAdminCredentials(){
          axios.put('/api/adminUpdate', this.paramsAdmin)
            .then(res => {
                // this.msg = res.data.message;
                // this.showAlert=true;
                // this.type= 'success';
                // setTimeout(() => {
                //   this.show=false;
                // }, 3000);
                this.dialog = false;
                this.admininfo();
                this.snackbar = true;
                this.snackBarText = res.data.message;
            })
            .catch(err => {
              console.error('err', err);
            })
      },
    },
    beforeCreate(){ 
    },
    created() {
      this.hotel_id = localStorage.getItem('hotel_id');
    },
    mounted() {
      this.admininfo();
      this.getUsers();
      // localStorage.removeItem('hotel_id');
    },
  }
</script>

<style>
  
.MenuStyle{
    cursor: pointer;
    color: rgb(28, 53, 59);
}

.MenuStyle:hover{
  background-color: rgb(6, 190, 240);
}


.MenuStyle:hover>.listMenuStyle{
  color: white;
}
.listMenuStyle{
  font-size: 11pt;
}
</style>