<template>
    <div>
        <navigationDrawer />
        <hotelTable />
    </div>
</template>
<script>
import navigationDrawer from '@/components/navigation_drawer.vue';
import hotelTable from '@/components/HotelTable.vue';

export default {
    data:()=>({
       
    }),
    components:{
        navigationDrawer,
        hotelTable
    }
}
</script>