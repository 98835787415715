<template>
<transition name="slide-fade" appear>
    <div class="divStyle">
        <v-container fluid  width="auto">
            <v-layout>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-card elevation="2">
                        <v-responsive>
                            <v-card-text class="text-center">
                            <h1>DASHBOARD</h1>
                            </v-card-text>
                        </v-responsive>
                        </v-card>
                    </v-col>
                </v-row>
            <!-- <v-container >
                    <v-card mx-6 class="d-flex flex-grow-1 primary darken-4 h-full v-card v-sheet theme--dark">
                        <v-responsive :aspect-ratio="4">
                            <v-card-text>
                            This card will always be 16:9 (unless you put more stuff in it)
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-container> -->
            </v-layout>

            <v-layout>
                <v-row>
                    <v-col  cols="12" sm="6" md="6">
                        <v-container>
                            <v-card>
                                <v-responsive>
                                    <v-list-item>
                                        <v-row dense>
                                            <v-col cols="6">
                                                <v-list-item-content style="padding-left:5px; background:#06bef0">
                                                    <p class=" text-overline mb-4 white--text">
                                                        Hotels
                                                    </p>
                                                    <v-list-item-title class="white--text" style="margin-bottom:5px">
                                                            Registered Hotels
                                                    </v-list-item-title>
                                                    <v-list-item-title class="white--text">
                                                            {{hotels}}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-list-item-content>
                                                    <v-icon
                                                        tile
                                                        size="80"
                                                        color="#00000099"
                                                    >
                                                    mdi-home-city-outline
                                                    </v-icon>
                                                </v-list-item-content>
                                            </v-col>
                                        </v-row>
                                    </v-list-item>
                                </v-responsive>
                            </v-card>
                        </v-container>
                    </v-col>

                    <v-col  cols="12" sm="6" md="6">
                        <v-container>
                            <v-card>
                                <v-list-item>
                                    <v-row dense>
                                        <v-col cols="6">
                                            <v-list-item-content style="padding-left:5px; background:#06bef0">
                                                <p class=" text-overline mb-4 white--text">
                                                    Associates
                                                </p>
                                                <v-list-item-title class="white--text" style="margin-bottom:5px">
                                                        Registered Associates
                                                </v-list-item-title>
                                                <v-list-item-title class="white--text">
                                                        {{hotels}}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-list-item-content>
                                                <v-icon
                                                    tile
                                                    size="80"
                                                    color="#00000099"
                                                >
                                                mdi-account-multiple-plus-outline
                                                </v-icon>
                                            </v-list-item-content>
                                        </v-col>
                                    </v-row>
                                </v-list-item>
                            </v-card>
                        </v-container>
                    </v-col>
                </v-row>
            </v-layout>

            <v-layout>
                <v-container>
                    <v-card>
                        <v-responsive :aspect-ratio="13">
                            <v-card-title>
                                Recent Clients 
                            </v-card-title>
                            <v-card-text>
                                <clientsTable />
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-container>
            </v-layout>

        <!-- <v-layout>
                <v-container>
                    <v-card mx-6>
                        <v-responsive :aspect-ratio="16/9">
                            <v-card-text>
                            This card will always be 16:9 (unless you put more stuff in it)
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-container>
                <v-container>
                    <v-card mx-6>
                        <v-responsive :aspect-ratio="16/9">
                            <v-card-text>
                            This card will always be 16:9 (unless you put more stuff in it)
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-container>
                <v-container>
                    <v-card mx-6>
                        <v-responsive :aspect-ratio="16/9">
                            <v-card-text>
                            This card will always be 16:9 (unless you put more stuff in it)
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-container>
            </v-layout> -->
        </v-container>
    </div>
</transition>
</template>

<script>
import axios from 'axios'; 
import clientsTable from '@/components/ClientsTable.vue';

export default {
    data() {
        return {
            hotels: null,
            asocciates: null
        }
    },
    computed: {
        height () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return true
          case 'sm': return true
          case 'md': return true
          case 'lg': return false
          case 'xl': return false
        }
      },
    },
    components:{
        clientsTable
    },
    methods: {
        async getInfo(){
            await axios.get("/api/hotel_cuantity")
                .then(res => {
                    this.hotels = res.data
                })
                .catch(err => {
                    console.error("respuesta",err);
            });
            await axios.get("/api/employees_cuantity")
                .then(res => {
                    this.asocciates = res.data
                })
                .catch(err => {
                    console.error("respuesta",err);
            });
        }
    },
    created() {
        this.getInfo();
    },
}
</script>

<style scoped>

.txtStyle {
    font-weight: bold;
    font-family: 'Montserrat', sans-serif !important;
}

.divStyle{
    background-color: #f1efef;
    width: 80%;
}

</style>