<template>
   <RecoverForm />
</template>
<script>
import RecoverForm from '@/components/login/RecoverForm.vue';
export default {
    data:()=>({
    }),
    components:{
        RecoverForm
    }
}
</script>


<style>
/*transition the opacity when the element enters and leaves the DOM*/



</style>