<!-- Desarollado por: jose luis casanova -->
<template>
<v-card height="100%" width="auto" elevation="0" style="position:absolute;" class="backgroundDrawer">
    <v-navigation-drawer app permanent :expand-on-hover="height" v-model="show" class="backgroundDrawer">
        <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="title">
                        <img src="img/prosafe-icon.webp" width="20%" height="auto" alt="">
                        <span class="txtStyle">
                            Admin {{user.name}}
                        </span>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        <v-divider></v-divider>
        <v-list dense nav class="pt-5">
            <v-list-item-group v-model="selectedItem" color="#0096c7">
                <v-list-item v-for="item in items" :key="item.title" link @click="item.action">
                    <v-list-item-icon>
                        <v-icon color="#ffffff" size="20">{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="txtStyle">{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
        <template v-slot:append>
                <v-list-item-group>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon color="#ffffff" size="20">mdi-exit-to-app</v-icon>
                        </v-list-item-icon>
                        
                        <v-list-item-content>
                            <v-list-item-title  class="txtStyle" @click="logout">
                                Logout
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
      </template>
    </v-navigation-drawer>
</v-card>
</template>

<script>
import axios  from 'axios'

export default {
    data() {
        return {
            user: {},
            show:true,
            selectedItem: 0,
            items: [{
                    title: 'Dashboard',
                    icon: 'mdi-view-dashboard',
                    action:()=>this.$router.push({name:'Home'})
                },
                {
                    title: 'Hotels',
                    icon: 'mdi-home-city',
                    action:()=>this.$router.push({name:'Hotels'})
                },
                {
                    title: 'Alerts Log',
                    icon: 'mdi-alert-decagram',
                    action:()=>this.$router.push({name:'Alerts'})
                },
            ],
            right: null,
        }
    },
    computed: {
        height () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return true
          case 'sm': return true
          case 'md': return true
          case 'lg': return false
          case 'xl': return false
        }
      },
    },
    created() {
        axios.get('api/user').then(res => {
            this.user = res.data
        });    
    },
    methods: {
        logout(){
            axios.post('/logout').then(() => {
                localStorage.removeItem('hotel_id');
                this.user = {}
                this.$router.push({name:'Login'})
            }); 
        },
    },
}
</script>

<style scoped>

.txtStyle {
    color: #ffffff;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif !important;
}
.backgroundDrawer {
    background-color: #005682 !important;
}

</style>
