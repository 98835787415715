<template>
<transition name="slide-fade" appear>
    <v-container>
        <v-layout row wrap justify-center>
          <v-card style="margin-top:60px" width="90%" height="500" elevation="0">
                <v-layout style="float:right">
                      <v-btn  @click="qrList()" color="#00ff80" small>
                        <span>
                            <v-icon>mdi-file-download-outline</v-icon>
                            Download All
                        </span>
                    </v-btn>
                </v-layout>
                <v-card-title class="align-left">
                    <h3 class="font-weight-medium">
                      Hotel Areas Information
                    </h3>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="desserts"
                  :items-per-page="10"
                  class="elevation-1"
                >
                    <template v-slot:item.actions="{ item }">

                        <v-container>
                          <v-layout>
                            <img width="60" :src="`data:image/png;base64,`+ item.qr" alt="">
                          </v-layout>
                          <a :download="'room-'+item.name" :href="`data:image/png;base64,` + item.qr" target="_blank">Download</a>
                        </v-container>
                    </template>
                    <template v-slot:no-data>
                          <v-progress-circular
                          :size="70"
                          :width="7"
                          color="primary"
                          indeterminate
                        ></v-progress-circular>
                    </template>
                </v-data-table>
          </v-card>
        </v-layout>     
    </v-container>
</transition>
</template>

<script>
import axios from 'axios';
import pdf from 'vue-pdf'
// <img :src="`data:image/png;base64,`+this.imagen" alt="">
// <pdf :src="pdfsrc"></pdf>
// url_server: 'http://localhost:8000', server local 
// url_server: 'prosafebackenddashboard.admin.com', server de pruebas

export default {
    data:()=>({
        hotel_id: null,
        url_server: process.env.VUE_APP_API_URL,
        headers: [
          { text: 'ID', value: 'id', align: 'center'},
          { text: 'Room Name', align: 'start', value: 'name', align: 'center'},
          { text: 'QR Code', value: 'actions', sortable: false, },
        ],
        desserts: [
        ],
        imagen: [],
        pdfsrc: null
    }),
    components: {
        pdf
    },
    created() {
      this.getQr();
    },
    methods: {
      async getHotels(){
        this.hotel_id = localStorage.getItem('hotel_id');
        await axios.get(`${"/api/hotel_info/"+this.hotel_id}`, {
          responseType: "blob"
        })
            .then(res => {
                const blob = new Blob([res.data]);
                const objectUrl = URL.createObjectURL(blob);
                this.pdfsrc = objectUrl;
            })
            .catch(err => {
                console.error("respuesta",err);
            });
      },
      async getQr(){
        this.hotel_id = localStorage.getItem('hotel_id');
        await axios.get("/api/hotel_areas/"+this.hotel_id)
            .then(res =>{
              this.imagen = res.data[0];
              this.desserts=[];
                res.data.forEach(element => {
                    this.desserts.push(element)
                });
            })
            .catch(err => {
            console.error("respuesta",err);
            });
      },
      qrList() {
            this.hotel_id = localStorage.getItem('hotel_id');
            window.open(this.url_server+"/api/hotel_qrlist_areas/" + this.hotel_id, "_blank").focus();
        },
    },
}
</script>

<style escoped>

.v-progress-circular {
  margin: 1rem;
}

</style>