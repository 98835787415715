var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide-fade","appear":""}},[_c('div',[_c('v-container',{staticClass:"mt-10"},[_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"absolute":"","rounded":"pill"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.snackBarText)+" ")])]),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"mx-auto;",staticStyle:{"margin-top":"20px","margin-bottom":"80px"},attrs:{"max-width":"344","outlined":"","elevation":"3"}},[_c('v-card-title',[_vm._v("HOTEL ADMIN INFO")]),_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_c('p',[_vm._v(" Name: "+_vm._s(this.name)+" ")])]),_c('div',{staticClass:"text-overline mb-4"},[_c('p',[_vm._v(" Email: "+_vm._s(this.email)+" ")])]),_c('div',{staticClass:"text-overline mb-4"},[_c('p',[_vm._v(" Hotel Name: "+_vm._s(this.hotel_name)+" ")])])])],1),_c('div',{staticClass:"ma-2"},[_c('v-menu',{attrs:{"transition":"slide-x-transition","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"deep-orange",attrs:{"dense":"","small":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" OPTIONS ")])]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"MenuStyle"},[_c('v-list-item-title',{staticClass:"listMenuStyle",on:{"click":function($event){return _vm.showModal('admin')}}},[_c('b',[_vm._v("Update Administrator Credentials")])])],1),_c('v-list-item',{staticClass:"MenuStyle"},[_c('v-list-item-title',{staticClass:"listMenuStyle",on:{"click":function($event){return _vm.showModal('allUsers')}}},[_c('b',[_vm._v("Update 6-Digit Pin For All Associates")])])],1)],1)],1)],1)],1),_c('v-card',[_c('v-card-title',{staticClass:"primary white--text text-h5"},[_vm._v(" Employees Directory ")]),_c('v-row',{staticClass:"pa-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-treeview',{attrs:{"active":_vm.active,"items":_vm.items,"load-children":_vm.fetchUsers,"open":_vm.open,"activatable":"","color":"warning","open-on-click":"","transition":""},on:{"update:active":function($event){_vm.active=$event},"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [(!item.children)?_c('v-icon',[_vm._v(" mdi-account ")]):_vm._e()]}}])})],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{staticClass:"d-flex text-center"},[_c('v-scroll-y-transition',{attrs:{"mode":"out-in"}},[(!_vm.selected)?_c('div',{staticClass:"text-h6 grey--text text--lighten-1 font-weight-light",staticStyle:{"align-self":"center"}},[_vm._v(" Select a Employee ")]):_c('v-card',{key:_vm.selected.id,staticClass:"pt-6 mx-auto",attrs:{"flat":"","max-width":"400"}},[_c('v-card-text',[(_vm.avatar)?_c('v-avatar',{attrs:{"size":"88"}},[_c('v-img',{staticClass:"mb-6",attrs:{"src":("https://avataaars.io/" + _vm.avatar)}})],1):_vm._e(),_c('h3',{staticClass:"text-h5 mb-2"},[_vm._v(" "+_vm._s(_vm.selected.name)+" ")]),_c('div',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.selected.email)+" ")]),_c('div',{staticClass:"blue--text subheading font-weight-bold"},[_c('a',{on:{"click":function($event){return _vm.showModal('user' ,_vm.selected.id)}}},[_vm._v(" Change 6-digit Pin "),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1)])],1),_c('v-divider'),_c('v-row',{staticClass:"text-left",attrs:{"tag":"v-card-text"}},[_c('v-col',{staticClass:"text-right mr-4 mb-2",attrs:{"tag":"strong","cols":"5"}},[_vm._v(" Company: ")]),_c('v-col',[_vm._v(_vm._s(_vm.selected.company))]),_c('v-col',{staticClass:"text-right mr-4 mb-2",attrs:{"tag":"strong","cols":"5"}},[_vm._v(" Brand: ")]),_c('v-col',[_vm._v(_vm._s(_vm.selected.brand))]),_c('v-col',{staticClass:"text-right mr-4 mb-2",attrs:{"tag":"strong","cols":"5"}},[_vm._v(" Phone: ")]),_c('v-col',[_vm._v(_vm._s(_vm.selected.phone))])],1)],1)],1)],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.modalOption === 'admin')?_c('v-toolbar',{attrs:{"flat":"","color":"#06bef0","dark":""}},[_c('div',{staticStyle:{"float":"right"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog=!_vm.dialog}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-toolbar-title',[_vm._v("Administrator")])],1):_c('v-toolbar',{attrs:{"flat":"","color":"#06bef0","dark":""}},[_c('div',{staticStyle:{"float":"right"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog=!_vm.dialog}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-toolbar-title',[_vm._v("Associates")])],1),_c('v-tabs',{attrs:{"color":"#06bef0"}},[(_vm.modalOption === 'admin')?_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-edit-outline ")]),_vm._v(" Credentials ")],1):_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-edit-outline ")]),_vm._v(" Change Pin ")],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('div',{staticClass:"text-center",staticStyle:{"margin-top":"2%"}},[_c('img',{staticClass:"rounded-circle ",staticStyle:{"margin-top":"20px"},attrs:{"src":"/img/associates_import-Icon.png","width":"50%","alt":""}})]),(_vm.modalOption === 'admin')?_c('v-card-text',[_c('v-form',{staticStyle:{"margin":"30px 20px 20px 20px"}},[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{attrs:{"mb-3":""}},[_c('v-col',[_c('v-text-field',{attrs:{"error-messages":errors,"label":"Email","type":"email","clearable":"","persistent-placeholder":""},model:{value:(_vm.paramsAdmin.email),callback:function ($$v) {_vm.$set(_vm.paramsAdmin, "email", $$v)},expression:"paramsAdmin.email"}})],1)],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{attrs:{"mb-3":""}},[_c('v-col',[_c('v-text-field',{attrs:{"error-messages":errors,"label":"Administrator Name","type":"text","clearable":"","persistent-placeholder":""},model:{value:(_vm.paramsAdmin.name),callback:function ($$v) {_vm.$set(_vm.paramsAdmin, "name", $$v)},expression:"paramsAdmin.name"}})],1)],1)]}}],null,true)}),_c('validation-provider',{attrs:{"vid":"form.password","rules":"min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{attrs:{"mb-3":""}},[_c('v-col',[_c('v-text-field',{attrs:{"error-messages":errors,"label":"Password","clearable":"","persistent-placeholder":"","append-icon":_vm.value ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.value ? 'password' : 'text'},on:{"click:append":function () { return (_vm.value = !_vm.value); }},model:{value:(_vm.paramsAdmin.password),callback:function ($$v) {_vm.$set(_vm.paramsAdmin, "password", $$v)},expression:"paramsAdmin.password"}})],1)],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"confirmed:form.password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{attrs:{"mb-3":""}},[_c('v-col',[_c('v-text-field',{attrs:{"error-messages":errors,"label":"Confirm Password","type":"password","clearable":"","persistent-placeholder":""},model:{value:(_vm.paramsAdmin.confirmed),callback:function ($$v) {_vm.$set(_vm.paramsAdmin, "confirmed", $$v)},expression:"paramsAdmin.confirmed"}})],1)],1)]}}],null,true)}),_c('validation-provider',{attrs:{"vid":"form.pin","rules":"minPin:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{attrs:{"mb-3":""}},[_c('v-col',[_c('v-text-field',{attrs:{"error-messages":errors,"label":"Pin","clearable":"","persistent-placeholder":"","append-icon":_vm.value1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.value1 ? 'password' : 'text'},on:{"click:append":function () { return (_vm.value1 = !_vm.value1); }},model:{value:(_vm.paramsAdmin.pin),callback:function ($$v) {_vm.$set(_vm.paramsAdmin, "pin", $$v)},expression:"paramsAdmin.pin"}})],1)],1)]}}],null,true)}),_c('div',{staticStyle:{"text-align":"center","padding-bottom":"20px","margin-top":"2px"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"#06bef0","dark":"","small":"","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.updateAdminCredentials()}}},[_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-content-save-edit-outline ")]),_c('span',{staticStyle:{"margin-left":"15px"},attrs:{"align":"center"}},[_vm._v("Update")])],1)],1)],1),_c('v-overlay',{attrs:{"opacity":0,"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}})],1)],1):(_vm.modalOption === 'allUsers')?_c('v-card-text',[_c('v-form',{staticStyle:{"margin":"30px 20px 20px 20px"}},[_c('validation-provider',{attrs:{"vid":"form.pin","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{attrs:{"mb-3":""}},[_c('v-col',[_c('v-text-field',{attrs:{"error-messages":errors,"label":"pin","clearable":"","persistent-placeholder":"","append-icon":_vm.value1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.value1 ? 'password' : 'text'},on:{"click:append":function () { return (_vm.value1 = !_vm.value1); }},model:{value:(_vm.params.pin),callback:function ($$v) {_vm.$set(_vm.params, "pin", $$v)},expression:"params.pin"}})],1)],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|confirmed:form.pin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{attrs:{"mb-3":""}},[_c('v-col',[_c('v-text-field',{attrs:{"error-messages":errors,"label":"Confirm Pin","type":"password","clearable":"","persistent-placeholder":""},model:{value:(_vm.params.confirmed),callback:function ($$v) {_vm.$set(_vm.params, "confirmed", $$v)},expression:"params.confirmed"}})],1)],1)]}}],null,true)}),_c('div',{staticStyle:{"text-align":"center","padding-bottom":"20px","margin-top":"2px"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"#06bef0","dark":"","small":"","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.updatePinAllUsers()}}},[_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-content-save-edit-outline ")]),_c('span',{staticStyle:{"margin-left":"15px"},attrs:{"align":"center"}},[_vm._v("Update")])],1)],1)],1)],1):(_vm.modalOption === 'user')?_c('v-card-text',[_c('v-form',{staticStyle:{"margin":"30px 20px 20px 20px"}},[_c('validation-provider',{attrs:{"vid":"form.pin","rules":"required|minPin:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{attrs:{"mb-3":""}},[_c('v-col',[_c('v-text-field',{attrs:{"error-messages":errors,"label":"Pin","clearable":"","persistent-placeholder":"","append-icon":_vm.value1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.value1 ? 'password' : 'text'},on:{"click:append":function () { return (_vm.value1 = !_vm.value1); }},model:{value:(_vm.params.pin),callback:function ($$v) {_vm.$set(_vm.params, "pin", $$v)},expression:"params.pin"}})],1)],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|confirmedPin:form.pin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{attrs:{"mb-3":""}},[_c('v-col',[_c('v-text-field',{attrs:{"error-messages":errors,"label":"Confirm Pin","type":"password","clearable":"","persistent-placeholder":""},model:{value:(_vm.params.confirmed),callback:function ($$v) {_vm.$set(_vm.params, "confirmed", $$v)},expression:"params.confirmed"}})],1)],1)]}}],null,true)}),_c('div',{staticStyle:{"text-align":"center","padding-bottom":"20px","margin-top":"2px"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"#06bef0","dark":"","small":"","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.updatePin()}}},[_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-content-save-edit-outline ")]),_c('span',{staticStyle:{"margin-left":"15px"},attrs:{"align":"center"}},[_vm._v("Update")])],1)],1)],1)],1):_vm._e()],1)],1)],1)]}}])})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }